class ProviderAccordion {
	constructor(accordion) {
		this.accordion = accordion;
		this.init();
	}

	init() {
		this.accordion
			.querySelectorAll('.provider-accordion__button')
			.forEach((button) => {
				button.addEventListener('click', () => {
					this.toggle(button);
				});
			});
	}

	toggle(button) {
		const isActive = button.classList.contains('active');

		this.accordion
			.querySelectorAll('.provider-accordion__button')
			.forEach((b) => {
				b.classList.remove('active');
				b.nextElementSibling.style.maxHeight = null;
			});

		if (!isActive) {
			button.classList.add('active');
			const accordionContent = button.nextElementSibling;
			accordionContent.style.maxHeight = accordionContent.scrollHeight + 'px';
		}
	}
}

export default ProviderAccordion;
