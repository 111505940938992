// import StickyHeader from './StickyHeader';

// class FacetWPHandler {
// 	constructor() {
// 		this.stickyHeader = new StickyHeader();
// 		this.initialLoad = true; // track the initial page load

// 		document.addEventListener('DOMContentLoaded', () => {
// 			this.addClassesToTemplates();
// 			this.attachButtonListeners();
// 			this.addLoadMoreButtonClasses();
// 		});

// 		document.addEventListener('facetwp-loaded', () => {
// 			this.handleFacetWPLoaded();
// 		});
// 	}

// 	addClassesToTemplates() {
// 		// Add classes to FacetWP job template
// 		const facetwpJobTemplate = document.querySelector(
// 			'.facetwp-template[data-name="job_template"]'
// 		);
// 		if (facetwpJobTemplate) {
// 			facetwpJobTemplate.classList.add(
// 				'job-listings__cards',
// 				'row',
// 				'gy-4',
// 				'justify-content-center'
// 			);
// 		}

// 		// Add classes to FacetWP news template
// 		const facetwpNewsTemplate = document.querySelector(
// 			'.facetwp-template[data-name="news_template"]'
// 		);
// 		if (facetwpNewsTemplate) {
// 			facetwpNewsTemplate.classList.add(
// 				'news-listings__cards',
// 				'row',
// 				'gy-4',
// 				'justify-content-center'
// 			);
// 		}
// 	}

// 	attachButtonListeners() {
// 		// Add event listener to search button
// 		const searchButton = document.getElementById('jobSearchButton');
// 		const resetButton = document.getElementById('resetFiltersButton');

// 		if (resetButton) {
// 			resetButton.addEventListener('click', (e) => {
// 				e.preventDefault();
// 				FWP.reset();
// 			});
// 		}

// 		if (searchButton) {
// 			searchButton.addEventListener('click', () => {
// 				FWP.refresh();
// 			});
// 		}
// 	}

// 	addLoadMoreButtonClasses() {
// 		const loadMoreButton = document.querySelector('.facetwp-load-more');
// 		if (loadMoreButton) {
// 			loadMoreButton.classList.add(
// 				'btn-pelling',
// 				'btn-pelling--z-blue',
// 				'outline',
// 				'mx-auto'
// 			);
// 		}
// 	}

// 	handleFacetWPLoaded() {
// 		const mainContent = document.querySelector('.main-content');
// 		const loadMoreButton = document.querySelector('.facetwp-load-more');

// 		if (
// 			this.initialLoad &&
// 			window.location.pathname !== '/resources/' &&
// 			mainContent
// 		) {
// 			const elementPosition =
// 				mainContent.getBoundingClientRect().top + window.pageYOffset;

// 			window.scrollTo({
// 				top: elementPosition - this.stickyHeader.getHeight(),
// 				behavior: 'smooth',
// 			});

// 			this.initialLoad = false;
// 		}

// 		this.addLoadMoreButtonClasses();
// 	}
// }

// export default FacetWPHandler;

import StickyHeader from './StickyHeader';

class FacetWPHandler {
	constructor() {
		this.stickyHeader = new StickyHeader();
		this.initialLoad = true; // track the initial page load

		document.addEventListener('DOMContentLoaded', () => {
			this.addClassesToTemplates();
			this.attachButtonListeners();
			this.addLoadMoreButtonClasses();
		});

		document.addEventListener('facetwp-loaded', () => {
			this.handleFacetWPLoaded();
		});
	}

	addClassesToTemplates() {
		// Add classes to FacetWP job template
		const facetwpJobTemplate = document.querySelector(
			'.facetwp-template[data-name="job_template"]'
		);
		if (facetwpJobTemplate) {
			facetwpJobTemplate.classList.add(
				'job-listings__cards',
				'row',
				'gy-4',
				'justify-content-center'
			);
		}

		// Add classes to FacetWP news template
		const facetwpNewsTemplate = document.querySelector(
			'.facetwp-template[data-name="news_template"]'
		);
		if (facetwpNewsTemplate) {
			facetwpNewsTemplate.classList.add(
				'news-listings__cards',
				'row',
				'gy-4',
				'justify-content-center'
			);
		}
	}

	attachButtonListeners() {
		// Add event listener to search button
		const searchButton = document.getElementById('jobSearchButton');
		const resetButton = document.getElementById('resetFiltersButton');

		if (resetButton) {
			resetButton.addEventListener('click', (e) => {
				e.preventDefault();
				FWP.reset();
			});
		}

		if (searchButton) {
			searchButton.addEventListener('click', () => {
				FWP.refresh();
			});
		}
	}

	addLoadMoreButtonClasses() {
		const loadMoreButton = document.querySelector('.facetwp-load-more');
		if (loadMoreButton) {
			loadMoreButton.classList.add(
				'btn-pelling',
				'btn-pelling--z-blue',
				'outline',
				'mx-auto'
			);
		}
	}

	// handleFacetWPLoaded() {
	// 	const mainContent = document.querySelector('.main-content');
	// 	const loadMoreButton = document.querySelector('.facetwp-load-more');

	// 	if (mainContent) {
	// 		const elementPosition =
	// 			mainContent.getBoundingClientRect().top + window.pageYOffset;

	// 		if (window.location.pathname === '/resources/') {
	// 			// Scroll when filters are applied after the initial load
	// 			if (!this.initialLoad) {
	// 				window.scrollTo({
	// 					top: elementPosition - this.stickyHeader.getHeight(),
	// 					behavior: 'smooth',
	// 				});
	// 			}
	// 		} else if (this.initialLoad) {
	// 			// Scroll on initial load for other pages
	// 			window.scrollTo({
	// 				top: elementPosition - this.stickyHeader.getHeight(),
	// 				behavior: 'smooth',
	// 			});
	// 		}

	// 		// Update the initialLoad flag after handling the first load
	// 		if (this.initialLoad) {
	// 			this.initialLoad = false;
	// 		}
	// 	}

	// 	this.addLoadMoreButtonClasses();
	// }

	// handleFacetWPLoaded() {
	// 	const mainContent = document.querySelector('.main-content');
	// 	const loadMoreButton = document.querySelector('.facetwp-load-more');

	// 	if (mainContent) {
	// 		const elementPosition =
	// 			mainContent.getBoundingClientRect().top + window.pageYOffset;

	// 		// Prevent scrolling on the /candidates/ page
	// 		if (window.location.pathname === '/candidates/') {
	// 			return;
	// 		}

	// 		if (window.location.pathname === '/resources/') {
	// 			// Scroll when filters are applied after the initial load
	// 			if (!this.initialLoad) {
	// 				window.scrollTo({
	// 					top: elementPosition - this.stickyHeader.getHeight(),
	// 					behavior: 'smooth',
	// 				});
	// 			}
	// 		} else if (this.initialLoad) {
	// 			// Scroll on initial load for other pages
	// 			window.scrollTo({
	// 				top: elementPosition - this.stickyHeader.getHeight(),
	// 				behavior: 'smooth',
	// 			});
	// 		}

	// 		// Update the initialLoad flag after handling the first load
	// 		if (this.initialLoad) {
	// 			this.initialLoad = false;
	// 		}
	// 	}

	// 	this.addLoadMoreButtonClasses();
	// }

	handleFacetWPLoaded() {
		const mainContent = document.querySelector('.main-content');
		const loadMoreButton = document.querySelector('.facetwp-load-more');

		if (mainContent) {
			const elementPosition =
				mainContent.getBoundingClientRect().top + window.pageYOffset;

			// Scroll to main content for specific pages
			if (window.location.pathname === '/resources/') {
				const urlParams = new URLSearchParams(window.location.search);

				// Check for specific query parameters
				if (
					urlParams.has('_news_categories') ||
					(!this.initialLoad && urlParams.toString())
				) {
					window.scrollTo({
						top: elementPosition - this.stickyHeader.getHeight(),
						behavior: 'smooth',
					});
				}
			} else if (
				this.initialLoad &&
				window.location.pathname !== '/candidates/'
			) {
				// Scroll on initial load for other pages (excluding /candidates/)
				window.scrollTo({
					top: elementPosition - this.stickyHeader.getHeight(),
					behavior: 'smooth',
				});
			}

			// Update the initialLoad flag after handling the first load
			if (this.initialLoad) {
				this.initialLoad = false;
			}
		}

		this.addLoadMoreButtonClasses();
	}
}

export default FacetWPHandler;
