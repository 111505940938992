class FormFileManager {
	constructor(formId, maxFileSize = 20 * 1024 * 1024, maxFileCount = 5) {
		this.singleFileInput = document.getElementById(`jobFile-${formId}`);
		this.multiFileInput = document.getElementById(`jobFilesInput-${formId}`);
		this.addFileButton = document.getElementById(`addFileButton-${formId}`);
		this.selectedFilesContainer = document.getElementById(
			`selectedFilesContainer-${formId}`
		);
		this.totalFileSizeElement = document.getElementById(
			`totalFileSize-${formId}`
		);

		this.selectedFiles = []; // Stores multi-upload selected files
		this.maxFileSize = maxFileSize;
		this.maxFileCount = maxFileCount;
		this.maxFileNameLength = 35; // Max length for file name formatting

		this.init();
	}

	init() {
		// Multi-file upload
		if (this.multiFileInput && this.addFileButton) {
			this.addFileButton.addEventListener('click', (e) => {
				e.preventDefault();
				if (
					!this.addFileButton.classList.contains(
						'multi-upload__button--disabled'
					)
				) {
					this.multiFileInput.click();
				}
			});
			this.multiFileInput.addEventListener('change', () =>
				this.handleMultiFileSelect()
			);
		}

		// Single-file upload
		if (this.singleFileInput) {
			this.singleFileInput.addEventListener('change', () =>
				this.handleSingleFileChange()
			);
		}

		// Remove files (for multi-upload)
		if (this.selectedFilesContainer) {
			this.selectedFilesContainer.addEventListener('click', (e) => {
				if (e.target.classList.contains('multi-upload__remove-file')) {
					const index = e.target.getAttribute('data-index');
					this.removeFile(index);
				}
			});
		}

		this.hideAddFileButton();
		this.updateTotalFileSizeDisplay();
		this.updateAddFileButtonState();
	}

	handleMultiFileSelect() {
		const files = Array.from(this.multiFileInput.files);

		// Check if adding these files would exceed the max file count
		const totalFileCount =
			this.selectedFiles.length +
			files.length +
			(this.singleFileInput.files.length > 0 ? 1 : 0);
		if (totalFileCount > this.maxFileCount) {
			alert(`You can only upload a maximum of ${this.maxFileCount} files.`);
			this.multiFileInput.value = '';
			return;
		}

		// Calculate total size if we add all the new files
		const totalSize =
			this.calculateTotalFileSize() +
			files.reduce((sum, file) => sum + file.size, 0);

		if (totalSize > this.maxFileSize) {
			alert(`The total size of selected files exceeds the limit of 20 MB.`);
			this.multiFileInput.value = '';
			return;
		}

		// All checks passed, add files
		this.selectedFiles.push(...files);

		this.updateSelectedFilesDisplay();
		this.updateTotalFileSizeDisplay();
		this.updateAddFileButtonState();

		// Clear multi-file input to allow selecting the same file again
		this.multiFileInput.value = '';
	}

	handleSingleFileChange() {
		if (!this.singleFileInput.files.length) {
			this.resetSingleFile();
			return;
		}

		const file = this.singleFileInput.files[0];

		// Check if adding this file would exceed the total file count
		const totalFileCount = this.selectedFiles.length + 1; // +1 for the single file
		if (totalFileCount > this.maxFileCount) {
			alert(`You can only upload a maximum of ${this.maxFileCount} files.`);
			this.singleFileInput.value = ''; // Clear invalid file
			return;
		}

		// Calculate total size without double-counting the single file
		const totalSize = this.calculateTotalFileSize();
		if (totalSize > this.maxFileSize) {
			alert(`The total size of selected files exceeds the limit of 20 MB.`);
			this.singleFileInput.value = ''; // Clear invalid file
			return;
		}

		// File is valid
		console.log(`Single file "${file.name}" is valid.`);
		this.showAddFileButton(); // Show "Upload More" button

		this.updateTotalFileSizeDisplay();
		this.updateAddFileButtonState();
	}

	resetSingleFile() {
		// Clear single file input
		this.singleFileInput.value = '';

		// Clear multi-upload files
		this.selectedFiles = [];
		this.updateSelectedFilesDisplay();

		// Reset total file size
		this.updateTotalFileSizeDisplay();

		// Hide the "Upload More" button
		this.hideAddFileButton();
		this.updateAddFileButtonState();
	}

	removeFile(index) {
		this.selectedFiles.splice(index, 1);
		this.updateSelectedFilesDisplay();
		this.updateTotalFileSizeDisplay();
		this.updateAddFileButtonState(); // Ensure button state is updated after removing a file
	}

	calculateTotalFileSize(includeSingleFile = true) {
		let totalSize = 0;

		// Add size of single upload file
		if (
			includeSingleFile &&
			this.singleFileInput &&
			this.singleFileInput.files.length > 0
		) {
			totalSize += this.singleFileInput.files[0].size;
		}

		// Add size of multi-upload files
		totalSize += this.selectedFiles.reduce((sum, file) => sum + file.size, 0);

		return totalSize;
	}

	updateTotalFileSizeDisplay() {
		const totalSize = this.calculateTotalFileSize();
		const humanReadableSize = (totalSize / (1024 * 1024)).toFixed(2);

		this.totalFileSizeElement.textContent = `Total size: ${humanReadableSize} MB (must not exceed 20 MB)`;

		if (totalSize > 15 * 1024 * 1024) {
			this.totalFileSizeElement.style.color = 'red';
		} else {
			this.totalFileSizeElement.style.color = 'inherit';
		}
	}

	updateSelectedFilesDisplay() {
		this.selectedFilesContainer.innerHTML = '';

		this.selectedFiles.forEach((file, index) => {
			const fileItem = document.createElement('div');
			fileItem.classList.add('multi-upload__file-item');

			// Use the formatFileName method to format long file names
			const formattedName = this.formatFileName(file.name);

			const fileName = document.createElement('span');
			fileName.textContent = formattedName;

			const removeButton = document.createElement('button');
			removeButton.textContent = '×';
			removeButton.classList.add('multi-upload__remove-file');
			removeButton.setAttribute('data-index', index);

			fileItem.appendChild(fileName);
			fileItem.appendChild(removeButton);
			this.selectedFilesContainer.appendChild(fileItem);
		});
	}

	updateAddFileButtonState() {
		const totalFileCount =
			this.selectedFiles.length +
			(this.singleFileInput.files.length > 0 ? 1 : 0);

		if (totalFileCount >= this.maxFileCount) {
			this.addFileButton.classList.add('multi-upload__button--disabled');
			this.addFileButton.disabled = true;
		} else {
			this.addFileButton.classList.remove('multi-upload__button--disabled');
			this.addFileButton.disabled = false;
		}
	}

	// Format file names
	formatFileName(fileName) {
		if (fileName.length <= this.maxFileNameLength) {
			return fileName;
		}

		const half = Math.floor((this.maxFileNameLength - 3) / 2);
		const start = fileName.slice(0, half);
		const end = fileName.slice(-half);

		return `${start}...${end}`;
	}

	showAddFileButton() {
		if (this.addFileButton) {
			this.addFileButton.style.display = 'inline-block';
		}
	}

	hideAddFileButton() {
		if (this.addFileButton) {
			this.addFileButton.style.display = 'none';
		}
	}
}

export default FormFileManager;
