class JobStepManager {
	constructor(steps, uiManager, formHandler) {
		this.steps = steps;
		this.uiManager = uiManager;
		this.formHandler = formHandler;
		this.currentAppStep = 1;

		this.hideAllSteps();
		this.showCurrentStep();
		this.attachApplyButtonListeners();

		window.addEventListener('popstate', (event) => {
			const step = event.state ? event.state.step : 1;
			this.handleBackNavigation(step);
		});
	}

	hideAllSteps() {
		this.steps.forEach((step) => (step.style.display = 'none'));
	}

	showStep(stepNumber, pushState = true) {
		this.hideAllSteps();
		const step = this.steps[stepNumber - 1];
		if (step) {
			step.style.display = 'block';
			this.uiManager.scrollToElement(step, stepNumber);
			this.currentAppStep = stepNumber;

			if (pushState) {
				window.history.pushState(
					{ step: stepNumber },
					'',
					`?step=${stepNumber}`
				);
			} else {
				window.history.replaceState(
					{ step: stepNumber },
					'',
					`?step=${stepNumber}`
				);
			}

			if (stepNumber === 3) {
				this.updateStep4Text();
			}
		}
	}

	showCurrentStep() {
		const urlParams = new URLSearchParams(window.location.search);
		const step = parseInt(urlParams.get('step'), 10) || 1;
		this.showStep(step, false);
	}

	handleBackNavigation(step) {
		if (this.currentAppStep === 3) {
			this.showStep(1, false);
			this.formHandler.resetForm();
		} else if (this.currentAppStep === 2) {
			this.showStep(1, false);
		} else if (this.currentAppStep === 1) {
			window.location.href = '/candidates/';
		}
		this.currentAppStep = step;
	}

	attachApplyButtonListeners() {
		const applyButtons = document.querySelectorAll(
			'.job-single__app-step--2 .job-single__button--apply'
		);
		applyButtons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				this.showStep(2);
			});
		});
	}

	updateStep4Text() {
		const step4Title = document.getElementById('step4Title');
		if (step4Title) {
			step4Title.textContent =
				'Thank you for your application, it has been sent to one of our consultants who will be in touch.';
		}
	}
}

export default JobStepManager;
