import Flickity from 'flickity';

class ClientLogos {
	constructor(block) {
		this.block = block;
		this.items = block.querySelector('.pelling-client-logos__clients');
		this.disableCarousel = block.classList.contains(
			'pelling-client-logos--disabled'
		);
		if (!this.disableCarousel) {
			this.flickityInit();
		}
	}

	flickityInit() {
		this.flkty = new Flickity(this.items, {
			cellAlign: 'center',
			wrapAround: true,
			prevNextButtons: false,
			autoPlay: 3000,
			pageDots: false,
			initialIndex: 2,
			on: {
				ready: () => {
					this.block.classList.add('loaded');
				},
			},
		});
	}
}

export default ClientLogos;
