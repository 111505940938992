import Flickity from 'flickity';

class ClientCandidateServices {
	constructor(block) {
		this.block = block;
		this.items = block.querySelector('.pelling-cc-services__services');
		this.flickityInit();
	}

	flickityInit() {
		if (this.items) {
			this.flkty = new Flickity(this.items, {
				cellAlign: 'center',
				wrapAround: true,
				prevNextButtons: true,
				autoPlay: 3000,
				pageDots: false,
			});

			this.flkty.on('staticClick', (event, pointer, cellElement, cellIndex) => {
				if (cellElement && this.flkty.selectedIndex !== cellIndex) {
					this.flkty.select(cellIndex);
				}
			});
		}
	}
}

export default ClientCandidateServices;
