class FormModalManager {
	constructor(modalId, successModalId) {
		this.modal = document.getElementById(modalId);
		this.successModal = document.getElementById(successModalId);
		this.successModalTitle = document.getElementById('successModalLabel');
		this.successModalBody = this.successModal.querySelector('.modal-body p');
	}

	close() {
		const modalInstance = bootstrap.Modal.getInstance(this.modal);
		modalInstance?.hide();
	}

	showSuccess(message, isSuccess = true) {
		this.successModalTitle.textContent = isSuccess ? 'Success' : 'Error';
		this.successModalBody.textContent = message;
		const successModalInstance = new bootstrap.Modal(this.successModal);
		successModalInstance.show();
	}
}

export default FormModalManager;
