class JobFormHandler {
	constructor(formId, dataService, uiManager, stepManager) {
		this.formId = formId;
		this.dataService = dataService;
		this.uiManager = uiManager;
		this.stepManager = stepManager;
		this.cf7Form = document.getElementById(this.formId);

		if (this.cf7Form) {
			document.addEventListener(
				'wpcf7beforesubmit',
				this.handleFormSubmit.bind(this)
			);
		}

		document.addEventListener(
			'wpcf7mailsent',
			this.handleFormSuccess.bind(this)
		);
		document.addEventListener('wpcf7invalid', this.handleFormError.bind(this));
		document.addEventListener('wpcf7spam', this.handleFormError.bind(this));
		document.addEventListener(
			'wpcf7mailfailed',
			this.handleFormError.bind(this)
		);

		this.setHiddenFieldValues();
	}

	async setHiddenFieldValues() {
		try {
			const { jobData, consultantData } =
				await this.dataService.fetchJobDetails();

			document.getElementById('app-form-job-title').value = jobData.acf.title;
			document.getElementById('consultant-email').value =
				consultantData.acf.email;
			document.getElementById('app-form-job-ref-number').value =
				jobData.acf.job_reference_number;
		} catch (error) {
			console.error('Error setting hidden field values:', error);
		}
	}

	handleFormSubmit(event) {
		// Check if the event is for the correct form
		// if (event.detail.contactForm != this.cf7Form) {
		// 	return;
		// }

		if (!this.validateFileSizes()) {
			event.preventDefault();
			this.uiManager.hideLoader();
			this.displayFileSizeError();
		} else {
			this.uiManager.showLoader();
		}
	}

	validateFileSizes() {
		const maxTotalSize = 20 * 1024 * 1024; // 20MB
		let totalSize = 0;
		const fileInputs = this.cf7Form.querySelectorAll('input[type="file"]');

		for (const fileInput of fileInputs) {
			for (const file of fileInput.files) {
				totalSize += file.size;
			}
		}

		return totalSize <= maxTotalSize;
	}

	displayFileSizeError() {
		let errorContainer = this.cf7Form.querySelector('.file-size-error');

		if (!errorContainer) {
			errorContainer = document.createElement('div');
			errorContainer.className = 'file-size-error';
			errorContainer.style.color = 'red';
			errorContainer.style.marginBottom = '15px';
			this.cf7Form.insertBefore(errorContainer, this.cf7Form.firstChild);
		}

		errorContainer.textContent =
			'Total file size exceeds the 20MB limit. Please reduce the file sizes and try again.';
	}

	handleFormSuccess(event) {
		if (this.cf7Form.contains(event.target)) {
			this.stepManager.showStep(3);
			this.uiManager.hideLoader();

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: 'job_application_submission' });
		}
	}

	handleFormError(event) {
		if (this.cf7Form.contains(event.target)) {
			this.uiManager.hideLoader();
		}
	}

	resetForm() {
		if (this.cf7Form) {
			this.cf7Form.reset();
			wpcf7.reset(this.cf7Form);
		}
	}
}

export default JobFormHandler;
